import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {IInitiativeSLice} from './IInitiativeState';
import {IIdeaDTO} from '../../../models/projects/ideas/idea/IIdeaDTO';
import {IDocumentSchemaDTO} from '../../../models/projects/ideaSchema/IDocumentSchemaDTO';
import {IDiscussionDTO} from '../../../models/projects/discussion/IDiscussionDTO';
import {IDiscussionComment} from '../../../models/projects/discussion/comment/IDiscussionComment';
import {IFileForUpload} from "../../../components/UI-Lib/LoadFileManager";
import {IStage} from "../../../models/projects/sberboost/IStage";
import {IExpertizeJournalDTO} from '../../../models/documents/ExpertizeJournal/IExpertizeJournalDTO';
import {TInitiativeViewMode} from "./TInitiativeViewMode";
import {ideaWithNewCalcs} from '../../../utils';
import {IInitiativeBlockInView} from './IInitiativeBlockInView';
import {IExpertizeJournalTaskSubject} from '../../../models/documents/ExpertizeJournal/IExpertizeJournalTaskSubject';
import { IDocumentSchemaInformation } from 'models/projects/ideaSchema/IDocumentSchemaInformation';

/**
 * слайс для хранения данных об инициативе
 */
const initiativeSlice = createSlice({
    name: 'Инициатива',
    initialState: {
      /**
       * идентификатор проекта, в котором находится инициатива
       */
      projectId: '',

      /**
       * отдельная инормация по версии и id схемы, чтобы не перезатирались данные для сохранения и публикации черновика
       */
      initiativeSchemaInformation: {},

      /**
       * схема документа инициативы
       */
      initiativeSchema: {},

      /**
       * блок инициативы, на который нужно прокрутить окно браузера
       */
      initiativeBlockInView: {
        id: '',
        needScroll: false
      },

      /**
       * тип контента в области показа
       */
      contentTypeInView: '',

      /**
       * данные инициативы
       */
      initiative: {},

      /**
       * обсуждение инициативы
       */
      discussion: {},

      /**
       * состояние экрана формы подачи инициативы
       */
      stages: {
        actual: {
          name: 'Описание',
          order: '1'
        },
        list: [
          {
            name: "Описание",
            order: '1',
          },
          {
            name: "Эффект",
            order: '2',
          },
          {
            name: "Публикация",
            order: '3',
          },
        ]
      },

      /**
       * состояние онбординга подачи инициативы
       */
      onboarding: {
        visible: false,
        stage: {
          id: '-1',
          fieldID: 'save'
        },
      },

      /**
       * калькулятор
       */
      // calculator: {
      //   resources: {
      //     title: "Экономия материальных ресурсов",
      //     text: "",
      //     value: 0,
      //     items: [
      //       {
      //         id: uuid(),
      //         title: '',
      //         amount: 0,  // кол-во
      //         cost: 0,    // стоимость
      //         period: {value: 'month', label: 'месяц', multiplier: 12}, // период выбирается из справочника
      //         value: 0
      //       },
      //     ]
      //   },
      //   sales: {
      //     title: "Рост продаж / Увеличение дохода от продаж",
      //     text: "",
      //     value: 0,
      //     items: [
      //       {
      //         id: uuid(),
      //         title: '',
      //         amount: 0,
      //         cost: 0,
      //         period: {value: 'month', label: 'месяц', multiplier: 12},
      //         value: 0
      //       },
      //     ]
      //   },
      //   workTime: {
      //     title: "Экономия рабочего времени",
      //     text: "",
      //     value: 0,
      //     items: [
      //       {
      //         id: uuid(),
      //         title: '', // Должность сотрудника
      //         minutes: 0, // кол-во высвобожденных минут
      //         days: 0, // Количество дней в месяц освобождаемых
      //         amountOperations: 0, // кол-во операций в день
      //         coastMinute: {value: '41.414', label: 'Средняя стоимость минуты сотрудника Банка', multiplier: 41.414}, // Текущая стоимость минуты раб. времени
      //         value: 0
      //       }
      //     ]
      //   },
      //   nonStandard: {
      //     title: "Эффект с нестандартной логикой расчета",
      //     text: "",
      //     value: 0,
      //     files: [],
      //     items: [
      //       {
      //         id: uuid(),
      //         title: '',
      //         profit: 0, //Доход / экономия
      //         period: {value: 'month', label: 'месяц', multiplier: 12}, // период
      //         value: 0
      //       }
      //     ]
      //   },
      //   clientExp: {
      //     id: "clientExp",
      //     title: "Улучшения клиентского опыта",
      //     type: "checkBoxes",
      //     checkboxList: [
      //       {
      //         id: "1",
      //         name: "Уменьшает операционные риски/вероятность ошибок процесса",
      //         value: false
      //       },
      //       {
      //         id: "2",
      //         name: "Делает покупку или получение услуги/продукта понятнее, прозрачнее, уменьшает вероятность ошибочной покупки",
      //         value: false
      //       }
      //     ]
      //   },
      //   metriki: {
      //     id: "metriki",
      //     title: "Критерии метрики «повышение удовлетворенности (CSI, NPS, ГВК»",
      //     type: "checkBoxes",
      //     checkboxList: [
      //       {
      //         id: "1",
      //         name: "Уменьшает операционные риски/вероятность ошибок процесса",
      //         value: false
      //       },
      //       {
      //         id: "2",
      //         name: "Делает покупку или получение услуги/продукта понятнее, прозрачнее, уменьшает вероятность ошибочной покупки",
      //         value: false
      //       },
      //       {
      //         id: "3",
      //         name: "Делает покупку или получение услуги/продукта удобнее - за счет интуитивно понятного дизайна/интерфейса",
      //         value: false
      //       },
      //       {
      //         id: "4",
      //         name: "Делает покупку или получение услуги/продукта быстрее - за счет сокращения времени на оказание услуги, исключение повторного ввода, количества бумаг, времени ожидания",
      //         value: false
      //       },
      //       {
      //         id: "5",
      //         name: "Делает совершение выбора и покупки продукта/получение услуги в комфортных условиях",
      //         value: false
      //       },
      //       {
      //         id: "6",
      //         name: "Делает понятной покупку или получение услуги/продукта",
      //         value: false
      //       },
      //       {
      //         id: "7",
      //         name: "Превосходит ожидания клиента после продажи продукта/оказания услуги/сервиса - просто/удобно/быстро получить повторную консультацию, техническую поддержку",
      //         value: false
      //       }
      //     ]
      //   }
      // },

      expertizeIsAvailable: false,

      /**
       * экспертиза инициативы
       */
      expertize: {},

      /**
       * обновить журнал экспертизы
       */
      updateExpertize: true,

      /**
       * экспертиза инициативы
       */
      initiativeViewMode: 'LEGACY',

      /**
       * экран уведомлений
       */
      isNotificationsScreenOpen: false

    } as IInitiativeSLice,
    reducers: {
      /**
       * запишем в слайс идентификатор проекта
       * @param state
       * @param action
       */
      setProjectId(state: IInitiativeSLice, action: PayloadAction<string>) {
        state.projectId = action.payload
      },

      /**
       * записать схему документа
       * @param state
       * @param action
       */
      setInitiativeSchema(state: IInitiativeSLice, action: PayloadAction<IDocumentSchemaDTO>) {
        state.initiativeSchema = action.payload
      },

      /**
       * записать информацию по схеме инициативе
       * @param state
       * @param action
       */
      setInitiativeSchemaInformation(state: IInitiativeSLice, action: PayloadAction<IDocumentSchemaInformation>) {
        state.initiativeSchemaInformation = action.payload
      },

      /**
       * обновить значение атрибута в схеме идеи
       * @param state
       * @param action
       */
      updateInitiativeSchemaAttributeValue(state: IInitiativeSLice, action: PayloadAction<{ attributeName: string, attributeValue: string }>) {
        state.initiativeSchema = {
          ...state.initiativeSchema,
          attributes: (state.initiativeSchema?.attributes || []).map(
            (attribute) => {
              if (attribute.name === action.payload.attributeName) return {
                ...attribute,
                value: action.payload.attributeValue
              }
              else return attribute
            }
          )
        }
      },

      /**
       * установить состояние идеи-инициативы
       * @param state
       * @param action
       */
      setInitiativeState(state: IInitiativeSLice, action: PayloadAction<IIdeaDTO>) {
        // state.initiative = action.payload
        state.initiative = ideaWithNewCalcs(action.payload)
      },

      /**
       * очистить данные о инициативе
       * @param state
       */
      clearInitiativeState(state: IInitiativeSLice) {
        state.initiative = {}
      },

      /**
       * обновить состояние инициативы
       * @param state
       * @param action
       */
      updateInitiativeState(state: IInitiativeSLice, action: PayloadAction<IIdeaDTO>) {
        state.initiative = {
          ...state.initiative,
          ...ideaWithNewCalcs(action.payload)
        }
      },

      /**
       * записать дискуссию полностью
       * @param state
       * @param action
       */
      setDiscussion(state: IInitiativeSLice, action: PayloadAction<IDiscussionDTO>) {
        state.discussion = action.payload
      },

      clearDiscussion(state: IInitiativeSLice) {
        state.discussion = {}
      },

      /**
       * обновить список комментариев в дискуссии
       * @param state
       * @param action
       */
      updateCommentListInDiscussion(state: IInitiativeSLice, action: PayloadAction<Array<IDiscussionComment>>) {
        state.discussion = {
          ...state.discussion,
          content: {
            ...state.discussion?.content,
            comments: {
              ...state.discussion?.content?.comments,
              content: [...action.payload]
            }
          }
        }
      },

      /**
       *  установить состояние onboarding
       * @param state
       * @param action
       */
      setOnboardingValue(state: IInitiativeSLice, action: PayloadAction<{ visible: boolean, stage: { id: string, fieldID: string } }>) {
        state.onboarding = action.payload;
      },

      /**
       * установить состояние шагов подачи инициативы
       * @param state
       * @param action
       */
      setStagesValue(state: IInitiativeSLice, action: PayloadAction<{ actual: IStage, list: Array<IStage> }>) {
        state.stages = action.payload
      },

      /**
       * установить состояние шага, как выполненный
       * @param state
       * @param action
       */
      // setStageAsComplete(state: IInitiativeSLice, action: PayloadAction<IStage>) {
      //   state.stages = {...state.stages, list: state.stages.list.map(s => action.payload.name === s.name ? {...s, complete: true} : s)}
      // },

      /**
       * обновить значение текстового атрибута
       * @param state
       * @param action
       */
      updateAttributeValue(state: IInitiativeSLice, action: PayloadAction<{ attributeName: string, attributeValue: string }>) {
        state.initiative = {
          ...state.initiative,
          document: {
            ...state.initiative.document,
            attributes: state.initiative.document?.attributes?.map(
              (attribute) => {
                if (attribute.name === action.payload.attributeName) return {
                  ...attribute,
                  value: action.payload.attributeValue
                }
                else return attribute
              }
            )
          }
        }
      },

      /**
       * обновить значение файла в атрибуте
       * @param state
       * @param action
       */
      updateAttributeFileValue(state: IInitiativeSLice, action: PayloadAction<{ attributeName: string, file: IFileForUpload }>) {

        const FilesState = state.initiativeSchema?.attributes?.find((attr) => attr.name === action.payload.attributeName)?.value

        state.initiativeSchema = {
          ...state.initiativeSchema,
          attributes: state.initiativeSchema.attributes?.map((attribute) => {
            if (attribute.name === action.payload.attributeName)
              return {
                ...attribute,
                value: FilesState.map((f: IFileForUpload) => {
                  return f.id === action.payload.file.id ? {...action.payload.file} : {...f}
                })
              }
            else
              return attribute
          })
        }
      },

      /**
       * установить id блока для прокрутки браузера
       */
      setInitiativeBlockInView(state: IInitiativeSLice, action: PayloadAction<IInitiativeBlockInView>) {
        state.initiativeBlockInView = {...action.payload}
      },

      /**
       * обновить экспертизу в редаксе
       * @param state
       * @param action
       */
      updateExpertize(state: IInitiativeSLice, action: PayloadAction<IExpertizeJournalDTO>) {
        state.expertize = {
          ...state.expertize,
          ...action.payload
        }
      },

      updateExpertizeAttributeValue(state: IInitiativeSLice, action: PayloadAction<{ taskId: string; attributeId: string; attributeValue: any }>) {
        state.expertize = {
          ...state.expertize,
          tasks: (state.expertize.tasks || []).map(
            task => {
              if (task.id !== action.payload.taskId) return task;
              return ({
                ...task,
                resultSubject: {
                  ...task.resultSubject,
                  attributes: (task.resultSubject?.attributes || []).map(
                    attribute => {
                      if (attribute.id !== action.payload.attributeId) return attribute;
                      // if (attribute.type?.value !== 'string') return attribute;
                      return {
                        ...attribute,
                        value: action.payload.attributeValue
                      }
                    }
                  )
                }
              })
            }
          )
        }
      },

      // обновить поле resultSubject задачи
      updateExpertizeTaskResultSubject(state: IInitiativeSLice, action: PayloadAction<{taskId: string, subject: IExpertizeJournalTaskSubject}>) {
        state.expertize = {
          ...state.expertize,
          tasks: (state.expertize.tasks || []).map(
            task => {
              if (task.id !== action.payload.taskId) return task;
              return ({
                ...task,
                resultSubject: {
                  ...action.payload.subject
                }
              })
            }
          )
        }
      },

      /**
       * @param state
       * @param action
       */
      setUpdateExpertize(state: IInitiativeSLice, action: PayloadAction<boolean>) {
        state.updateExpertize = action.payload
      },

      setInitiativeViewMode(state: IInitiativeSLice, action: PayloadAction<TInitiativeViewMode>) {
        state.initiativeViewMode = action.payload
      },

      setExpertizeIsAvailable(state: IInitiativeSLice, action: PayloadAction<boolean>) {
        state.expertizeIsAvailable = action.payload
      },

      setNotificationsScreenOpen(state: IInitiativeSLice, action: PayloadAction<boolean>) {
        state.isNotificationsScreenOpen = action.payload
      }
    }
})

export const {
  setProjectId,
  setInitiativeSchemaInformation,
  setInitiativeSchema,
  updateInitiativeSchemaAttributeValue,
  setInitiativeState,
  clearInitiativeState,
  updateInitiativeState,
  setDiscussion,
  clearDiscussion,
  updateCommentListInDiscussion,
  updateAttributeValue,
  updateAttributeFileValue,

  setOnboardingValue,
  setStagesValue,
  setNotificationsScreenOpen,
  setInitiativeBlockInView,
  updateExpertize,
  setUpdateExpertize,
  updateExpertizeAttributeValue,
  updateExpertizeTaskResultSubject,
  setInitiativeViewMode,
  setExpertizeIsAvailable
} = initiativeSlice.actions;

export default initiativeSlice.reducer;